import { Component, OnInit } from '@angular/core';
import { StatisticComponent } from '@components/statistic/statistic.component';
import { ProjectsComponent } from '@components/projects/projects.component';
import { MatIconModule } from '@angular/material/icon';
import { ClientsSpeakComponent } from '@components/clients-speak/clients-speak.component';
import { ContactUsComponent } from '@components/contact-us/contact-us.component';
import { RouterModule } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { Observable } from 'rxjs';
import { Project } from '@interfaces/project.interface';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    StatisticComponent,
    ProjectsComponent,
    MatIconModule,
    ClientsSpeakComponent,
    ContactUsComponent,
    RouterModule,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  public projects: Observable<Project[]> | null = null;

  constructor(private dataService: DataService) {}

  ngOnInit():void {
    this.projects = this.dataService.projects;
  }
}
